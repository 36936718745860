export const EMPTY_STRING: string = "";
export const STRING_PLACEHOLDER: string = "--";
export const INPUT_DECIMAL_STEP: string = "0.000000000001";

export const HTTP: Record<string, number> = {
  NOT_FOUND: 404,
  OK: 200,
  NO_CONTENT: 204,
};

export const LIMIT_DOWNLOAD: number = 100;
// --> Si LIMIT_DOWNLOAD_ORDERS = 0 no hay límite
export const LIMIT_BULK_ACTIONS: number = 0;
export const LIMIT_DOWNLOAD_ORDERS: number = 10000;
// <--
export const LIMIT_DOWNLOAD_SHIPPING_LABELS: number = 300;
export const LIMIT_DOWNLOAD_DELIVERY_NOTES: number = 500;
export const LIMIT_DOWNLOAD_CMR: number = 300;
export const LIMIT_EXPORT: number = 2000;
export const LIMIT_EXPORT_LINEHAUL = 100;
export const DATE_TEMPLATE = "yyyy-MM-dd";
export const DATE_TIME_TEMPLATE = "yyyy-MM-dd HH:mm";
export const DATE_TIME_TEMPLATE_SECONDS = "yyyy-MM-dd HH:mm:ss";
export const DATE_TEMPLATE_REVERSE = "dd/MM/yyyy";
export const TIME_TEMPLATE = "HH:mm";
export const TIME_TEMPLATE_SECONDS = "HH:mm:ss";

export const DEFAULT_TIMES: Record<string, string> = {
  startTime: "00:00:00",
  endTime: "23:59:59",
};

export const PAGE_STATUS: Record<string, number | string> = {
  current_page: 0,
  from: 0,
  last_page: 0,
  path: "",
  per_page: 0,
  to: 0,
  total: 0,
};

export const VIEWPRESETS: Record<string, string> = {
  default: "default",
  my_pin: "myPin",
  custom: "custom",
  raw: "raw",
};

export const dateTopologiesNoEmpty: Topology[] = [
  { operator: "date/is.exactly" },
  { operator: "date/today" },
  { operator: "date/yesterday" },
  { operator: "date/tomorrow" },
  { operator: "date/this.week" },
  { operator: "date/last.week" },
  { operator: "date/last.fourteen.days" },
  { operator: "date/rest.of.month" },
  { operator: "date/last.month" },
  { operator: "date/last.three.months" },
  { operator: "date/this.month.so.far" },
  { operator: "date/next.month" },
  { operator: "date/is.in.or.after.to" },
  { operator: "date/is.in.or.before.to" },
  { operator: "date/date.range" },
];

export const dateTopologiesNoEmptyDates: Topology[] = [
  { operator: "date/is.exactly" },
  { operator: "date/today" },
  { operator: "date/yesterday" },
  { operator: "date/tomorrow" },
  { operator: "date/is.in.or.after.to" },
  { operator: "date/is.in.or.before.to" },
  { operator: "date/date.range" },
];
