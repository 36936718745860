<template>
  <div>
    <label
      v-if="$slots.default"
      :for="name"
      class="block text-sm font-medium leading-6 text-gray-900"
      ><slot>Simple Input label</slot></label
    >
    <div class="relative mt-1 rounded-md">
      <input
        ref="input"
        :type="type"
        :name="name"
        :id="id"
        :min="min"
        :max="max"
        :step="step"
        v-model="selectedValue"
        :class="[
          'block w-full rounded-md border-0 px-2 py-1.5 text-gray-900  ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6 [&:has(+span)]:pr-10',
          {
            'pr-10 text-red-900 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500':
              error?.exist,
          },
        ]"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <div
        v-if="error?.exist"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <ExclamationCircleIcon class="size-5 text-red-500" aria-hidden="true" />
      </div>
      <span
        v-if="$slots.legend && !error?.exist"
        class="absolute inset-y-0 right-0 flex items-center pr-4 text-sm leading-5 text-gray-500"
      >
        <slot name="legend"></slot>
      </span>
    </div>
    <p
      v-if="error?.exist"
      data-test="input-box-error"
      class="mt-2 text-sm text-red-600"
      id="email-error"
    >
      {{ error?.message }}
    </p>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="T extends string | number | boolean | null | undefined"
>
import { ExclamationCircleIcon } from "@heroicons/vue/20/solid";
import { computed, ref } from "vue";

const emit = defineEmits<{
  "update:modelValue": [value: T];
}>();

const props = defineProps<{
  modelValue: T;
  type?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: Record<string, unknown>;
  min?: string;
  max?: string;
  step?: string;
}>();

const input = ref(null);

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
