import { inject } from "vue";
import flatten from "lodash-es/flatten";
import type { ErrorObject, Validation } from "@vuelidate/core";
import type { ComponentInstance } from "@/common/plugins/alert";
import type { Label } from "@/common/components/FilterMachine";
import has from "lodash-es/has";
import type { Qp } from "@letsgoi/qp";
import { useRouter } from "vue-router";

export default function useErrorHandler() {
  const alert: ComponentInstance = inject("alert", {} as ComponentInstance);
  const router = useRouter();

  const AUTO_CLOSE_IN_MS = 8000;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extractError = async (exception: any): Promise<FormatError> => {
    const status = exception?.response?.status ?? 0;
    if (status === 422) {
      const dataError = await exception?.response?.json();
      if (dataError?.message) {
        return { status, messages: [dataError?.message] };
      } else {
        const errors: string[] = flatten(
          Object.values(dataError?.errors ?? [])
        ).map(
          (error) =>
            (dataError?.error_line
              ? `${error} (Error in line: ${dataError?.error_line})`
              : error) as string
        );
        return { status, messages: errors };
      }
    } else if ([400, 401, 403, 404].includes(status)) {
      const dataError = await exception?.response?.json();
      return { status, messages: [dataError?.message] };
    } else {
      return {
        status,
        messages: [
          exception.message
            ? exception.message
            : "There was a problem. Please, try again and if the problem persists contact us at tech{'@'}letsgoi.com",
        ],
      };
    }
  };
  const handleApiExceptions = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exception: any,
    show: boolean = true
  ): Promise<Array<string> | string> => {
    if (exception?.response?.status === 422) {
      console.error(exception);
      const dataError = await exception?.response?.json();
      if (dataError?.message) {
        if (show)
          alert?.error([dataError?.message], {
            autoCloseInMs: AUTO_CLOSE_IN_MS,
          });
        console.error(dataError?.message);
        return dataError?.message;
      }
      const errors: string[] = flatten(
        Object.values(dataError?.errors ?? [])
      ).map(
        (error) =>
          (dataError?.error_line
            ? `${error} (Error in line: ${dataError?.error_line})`
            : error) as string
      );
      if (show) alert?.error(errors, { autoCloseInMs: AUTO_CLOSE_IN_MS });
      console.error(errors);
      return errors as Array<string>;
    } else if (exception?.response?.status === 403) {
      console.error(exception);
      const dataError = await exception?.response?.json();
      if (show)
        alert?.error([dataError?.message], { autoCloseInMs: AUTO_CLOSE_IN_MS });
      console.error(dataError?.message);
      return dataError;
    } else if (exception?.response?.status === 400) {
      console.error(exception);
      const dataError = await exception?.response?.json();
      if (show)
        alert?.error([dataError?.message], { autoCloseInMs: AUTO_CLOSE_IN_MS });
      console.error(dataError?.message);
      return dataError;
    } else if (exception?.response?.status === 404) {
      console.error(exception);
      const dataError = await exception?.response?.json();
      if (show)
        alert?.error([dataError?.message], { autoCloseInMs: AUTO_CLOSE_IN_MS });
      console.error(dataError?.message);
      return dataError;
    } else if (exception?.response?.status === 306) {
      const dataError = await exception?.response?.json();
      console.error(dataError?.message);
      return dataError?.message;
    } else if (exception?.response?.status === 401) {
      const dataError = await exception?.response?.json();
      if (show)
        alert?.error([dataError?.message], { autoCloseInMs: AUTO_CLOSE_IN_MS });
      console.error(dataError?.message);
      try {
        router.push({ name: "authLogin" });
      } catch {
        globalThis.location.href = "/auth/login";
      }
      return dataError;
    } else {
      console.error(exception);
      if (show)
        alert?.error(
          [
            "There was a problem. Please, try again and if the problem persists contact us at tech{'@'}letsgoi.com",
            exception.message,
          ],
          { autoCloseInMs: AUTO_CLOSE_IN_MS }
        );
      return "default error";
    }
  };

  const formatErrors = (errors: Array<ErrorObject> = []): Array<string> =>
    errors?.map((error) => {
      const fieldName = error?.$response?.fieldDescription || error?.$property;
      const message = error?.$response?.message || error?.$message;
      return `(${fieldName}) ${message}`;
    });
  const alertVuelidateErrors = (v$: Validation) => {
    alert?.error(formatErrors(v$.$errors), { autoCloseInMs: 10000 });
  };

  const testUrlSyntaxErrors = (
    queryParams: Qp.FilterValue<Operator, Label>[]
  ) =>
    queryParams.some(
      (filter) => has(filter, "ref.id") || has(filter, "ref.operation")
    );

  return {
    handleApiExceptions,
    alertVuelidateErrors,
    formatErrors,
    testUrlSyntaxErrors,
    extractError,
  };
}

export type ErrorHandler = ReturnType<typeof useErrorHandler>;
