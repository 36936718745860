declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $customLog(message: string): void;
  }
}

export default {
  install() {
    globalThis.log = function (message: string): void {
      const e = new Error();
      const stack = e.stack?.split(/\r\n|\n/);
      const stackLine = stack && stack[2];

      if (!stackLine) return;

      const fileInfo = /at\s+(?:.*\()?(.*?):(\d+):(\d+)\)?$/.exec(stackLine);
      if (!fileInfo) return;

      const fullPath = fileInfo[1];
      const lineNumber = fileInfo[2];

      // Extraer solo el nombre del archivo de la ruta completa y limpiar parámetros de consulta
      let fileName = fullPath.split("/").pop();
      if (fileName) {
        fileName = fileName.split("?")[0]; // Elimina los parámetros de consulta
      }

       
      console.log(`[${fileName}:${lineNumber}]`, message);
    };
  },
};
